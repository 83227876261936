import logo from '../img/logo-black-white-text.svg';

export default function Header() {
  return (
    <div className="bg-opacity-50 flex justify-center">
      <a href="/">
        <img src={logo}
          className="inline-block h-10 sm:h-8 w-auto mx-auto opacity-80 hover:opacity-100 transition-all duration-150 ease-in-out"
          alt="infrascale logo"
        />
      </a>
    </div>
  );
}
