import ContactForm from "./components/ContactForm";
import Features from "./components/Features";
import Hero from "./components/Hero";

function App() {
  return (<>
    <Hero />
    <Features />
    {/* <Brands /> */}
    <ContactForm />
  </>);
}

export default App;
